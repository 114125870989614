import { del } from "../apiClient";

export async function deleteFav(favId: number) {
  const response = await del(`/user/favorites/${favId}`, "wl-logostore-tester");

  if (!response.success) {
    const err = await JSON.parse(JSON.stringify(response));
    throw err.message ?? "An error occurred";
  }

  const json = await JSON.parse(JSON.stringify(response));

  if (json.data == null) {
    return null;
  }

  return json;
}
