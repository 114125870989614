import { put } from "../apiClient";

export async function addFav({ brandId, imageId }: { brandId: number; imageId: number }) {
  console.log("IDS", brandId, imageId);
  const response = await put(`/user/favorite`, "wl-logostore-tester", {
    userFavoriteId: 0,
    brandId,
    imageId,
    isFavourite: true,
  });

  if (!response.success) {
    const err = await JSON.parse(JSON.stringify(response));
    throw err.message ?? "An error occurred";
  }

  const json = await JSON.parse(JSON.stringify(response));

  if (json.data == null) {
    return null;
  }

  return json;
}
