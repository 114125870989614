import { put } from "../apiClient";

export async function sendSavedSearch(savedSearchName: string, logoList: string[]) {
  try {
    const listValues = logoList.map((item: any) => {
      return item.Value;
    });

    const payload = { Id: 0, Name: savedSearchName, SearchRequest: listValues };

    const response = await put("/user/saved-search", "wl-logostore-tester", payload);
    const json = await JSON.parse(JSON.stringify(response));
    if (json.data == null) {
      return null;
    }

    return json;
  } catch (error: any) {
    console.log(error.response);
    throw new Error(error.response.data.detailedMessages[0]);
  }
}
