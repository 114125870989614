import React from "react";
import chev from "../../assets/chev.svg";
import "./Styles/SaveSearch.css";

const SaveSearch = ({
  setIsAddNewSearchOpen,
  isAddNewSearchOpen,
  isViewSavedHistoryOpen,
  setIsViewSavedHistoryOpen,
  areSearchTagsEmpty,
  fetchSavedList,
}: any) => {
  return (
    <div className="ss-save-search-container">
      <button
        disabled={areSearchTagsEmpty}
        onClick={() => {
          setIsAddNewSearchOpen(!isAddNewSearchOpen);
          setIsViewSavedHistoryOpen(false);
        }}
        className={
          isAddNewSearchOpen
            ? "ss-save-search-btn-open"
            : areSearchTagsEmpty
            ? "ss-save-search-btn-disabled"
            : "ss-save-search-btn"
        }
      >
        Save Search
      </button>
      <button
        onClick={() => {
          fetchSavedList(1, 5);
          setIsAddNewSearchOpen(false);
          setIsViewSavedHistoryOpen(!isViewSavedHistoryOpen);
        }}
        className={isViewSavedHistoryOpen ? "ss-save-search-drop-open" : "ss-save-search-drop"}
      >
        <img
          className={`ss-save-search-icon ${isViewSavedHistoryOpen ? "ss-save-search-icon-open" : ""}`}
          src={chev}
          alt="chev"
        />
      </button>
    </div>
  );
};

export default SaveSearch;
