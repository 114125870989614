import { loadTheme, mergeStyles, registerIcons } from "@fluentui/react/lib/Styling";
import { appTheme, actionButtonTheme } from "./theme";
import { Provider } from "react-redux";
import { store } from "./Src/Redux/store/store";

loadTheme(appTheme);

import App from "./App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
// import { SignoutIcon } from "../icons/signout";
import { ThemeProvider } from "@fluentui/react";
import * as React from "react";
import { createRoot } from "react-dom/client";
import "./style.css";
/* global document, Office, module, require */

initializeIcons();
// registerIcons({

//   icons: {
//     SignoutWL: <SignoutIcon />,
//   },
// });

let isOfficeInitialized = false;

const resetClass = mergeStyles([
  {
    margin: 0,
    padding: 0,
    height: "100%",
    width: "100%",
  },
]);

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render(App);
});

const render = (Component: typeof App) => {
  const container = document.getElementById("container");
  const root = createRoot(container!);
  root.render(
    <Provider store={store}>
      <AppContainer>
        <ThemeProvider className={resetClass} theme={appTheme}>
          <ThemeProvider className={resetClass} theme={actionButtonTheme} applyTo="element">
            <Component isOfficeInitialized={isOfficeInitialized} />
          </ThemeProvider>
        </ThemeProvider>
      </AppContainer>
    </Provider>
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render(App);
});

if ((module as any).hot) {
  (module as any).hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}
