import { post } from "../apiClient";

export async function getSavedSearches(pageNum: number, pageSize: number) {
  const response = await post("/user/saved-searches", "wl-logostore-tester", { pageNum, pageSize });

  if (!response.success) {
    const err = await JSON.parse(JSON.stringify(response));
    throw err.message ?? "An error occurred";
  }

  const json = await JSON.parse(JSON.stringify(response));

  if (json.data == null) {
    return null;
  }

  return json;
}
